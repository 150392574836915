import * as PIXI from 'pixi.js';
import gsap from "gsap";

let asteroidID = 0
export function generateAsteroid(sprites,bounds,settings,startX,startY,parentLifeStage) {
    let asteroid
    if(!parentLifeStage) {
        asteroid = PIXI.Sprite.from(sprites.asteroid)
        asteroid.radius = 45;
        asteroid.rX = gsap.utils.random(-1,1)
        asteroid.rY = gsap.utils.random(-1,1)
    } else {
        switch(parentLifeStage) {
            case 1: 
                asteroid = PIXI.Sprite.from(sprites.asteroidSm); 
                asteroid.radius = 20;
                asteroid.rX = gsap.utils.random(-2,2)
                asteroid.rY = gsap.utils.random(-2,2)
                break;
            case 2: 
                asteroid = PIXI.Sprite.from(sprites.asteroidMid); 
                asteroid.radius = 45;
                asteroid.rX = gsap.utils.random(-1.5,1.5)
                asteroid.rY = gsap.utils.random(-1.5,1.5)
                break;
        }
    }

    asteroid.asteroidID = asteroidID
    
    // asteroid.liveStage = 3

    if(!parentLifeStage) {
        asteroid.lifeStage = 2
    } else {
        asteroid.lifeStage = parentLifeStage - 1
    }

    asteroid.anchor.set(0.5)

    if(!startX && !startY) {
        asteroid.x = 0 - (settings.asteroidSize/2) + 1
        asteroid.y = 0 - (settings.asteroidSize/2) + 1
    }

    if(startX && startY) {
        asteroid.x = startX 
        asteroid.y = startY
    }
    
    // asteroid.rX = gsap.utils.random(-1,1)
    // asteroid.rY = gsap.utils.random(-1,1)

    asteroid.rDir = ( Math.random() > .5 ) ? 1 : -1

    asteroidID++

    asteroid.circular = true;
    //  asteroid.radius = (asteroid.width/2) - 40;
    //gsap.ticker.add(asteroid.rot)
    return asteroid
    //gd.stage.addChild(asteroid)
}
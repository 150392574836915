export const settings = {
    asteroidSize: 100, // size in px of the asteroids artboard
    asteroidCount : 10, // number of starting asteroids
    asteroidRotationSpeed: 0.005, // Speed the asteroids rotate per frame
    asteroidSpeed: 0.05, // Speed the asteroids move per frame
    asteroidCountdown: 1000, // number of frames before a new asteroid is generated
    playerMaxSpeed: 10, // Maximum speed the player can reach
    playerAcceleration: 0.1, // Increase in player speed per frame when accelerating
    playerDeceleration: 0.05, // Decrease in player speed per frame when slowing
    playerRotationSpeed: 0.025, // Speed of the player rotation per frame
    playerSize: 30, // sizez of the player sprite in px,
    playerSizeHalf: 15, // half the size of the player sprite
    pointsPerAsteroid: 100, // number of points you score per asteroid destroyed
    bulletSpeed: 0.5, // time it takes a bullet to go from the player to its end point in seconds
    bulletDistance: 1000,//300, // distance a bullet travels after it fires
    bulletCoolDown: 20,//40, // time in frames between valid shots
}
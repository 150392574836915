// Imports
import * as PIXI from 'pixi.js';
import gsap from "gsap";
import {Howl, Howler} from 'howler';
// import '/src/style.css';
import { generateAsteroid } from './scripts/asteroids';
import { Bump } from './scripts/bump';
const b = new Bump(PIXI)
import { settings } from './scripts/settings.js'
import {CRTFilter} from 'pixi-filters';


let gd, sprs, player, score = 0, highScore = 0, lives = 3, shooting
let up , down, left, right, space
let livesGUI , scoreGUI
let asteroids = []
let bullets = []

let gameLayer, guiLayer , screenLayer , frameLayer
// screens
let titleScreen, endScreen, gameOverText
let filter

let credits , tutorialText

let asteroidCountdown = settings.asteroidCountdown

/* 
states 
-1 = pause state // used for transitions
0 = start game 
1 = in game
2 = gameover!
*/
let state = -1


const el = document.querySelector('#stage')
let bounds = {
    height: el.clientHeight,
    width: el.clientWidth,
    center: {
        x: el.clientWidth / 2,
        y: el.clientHeight / 2
    }
}


const assetsPack = {
    titleScreen: './title_screen.png',
    ship: './ship.png',
    asteroid: './asteroid.png',
    asteroidMid: './asteroid_mid.png',
    asteroidSm: './asteroid_sm.png',
    bullet:'./bullet.png',
    fontThin: './Vectrex-Big.otf',
    fontBold: './Vectrex-Small.otf',
    //introMusic: 'intro.mp3',
}

function init() {
    gd = new PIXI.Application({
        width: bounds.width,
        height: bounds.height,
        backgroundColor: 0x191919
    })
    el.appendChild(gd.view)

    let backgroundLayer = new PIXI.Container()
    gd.stage.addChild(backgroundLayer)
    let bkg = new PIXI.Graphics()
    bkg.beginFill(0x191919,1)
    bkg.drawRect(0,0,bounds.width,bounds.height)
    bkg.endFill()
    backgroundLayer.addChild(bkg)

    gameLayer = new PIXI.Container()
    gd.stage.addChild(gameLayer)

    guiLayer = new PIXI.Container()
    gd.stage.addChild(guiLayer)

    screenLayer = new PIXI.Container()
    gd.stage.addChild(screenLayer) 

    frameLayer = new PIXI.Container()
    gd.stage.addChild(frameLayer)

    let frame = new PIXI.Graphics()
    frame.lineStyle(2,0xa79ac8,1)
    frame.drawRect(0,0,bounds.width,bounds.height)
    frameLayer.addChild(frame)


    filter = new CRTFilter({
        time: 0,
        vignettingAlpha: 0.5,
        vignettingBlur: 0.1,
        lineWidth: 2,
        curvature: 1.5,
        lineContrast: 0.5,
        noise: 0.05,
    })
    //gameLayer.filters = [filter]
    gd.stage.filters = [filter]

    PIXI.Assets.addBundle('artwork', assetsPack)
    const assets = PIXI.Assets.loadBundle('artwork')
    assets.then((loadedSprites) => {
        sprs = loadedSprites
        titleScreen = PIXI.Sprite.from(sprs.titleScreen)
        titleScreen.anchor.set(0.5)
        titleScreen.x = bounds.center.x
        titleScreen.y = bounds.center.y
        screenLayer.addChild(titleScreen)
        state = 0

        livesGUI = new PIXI.Text('Lives: 3', {
            fontFamily: 'Vectrex Small',
            fontSize: 20,
            fill: 0xa79ac8,
            align: 'center',
        });
        livesGUI.anchor.set(1,0)
        livesGUI.x = bounds.width - 5
        
        guiLayer.addChild(livesGUI)

        scoreGUI = new PIXI.Text('Score: 0', {
            fontFamily: 'Vectrex Small',
            fontSize: 20,
            fill: 0xa79ac8,
            align: 'center',
        });
        scoreGUI.x = 5
        guiLayer.addChild(scoreGUI)
        

        credits = new PIXI.Text('Player research & unitedus \n presents', {
            fontFamily: 'Vectrex Big',
            fontSize: 30,
            fill: 0xa79ac8,
            align: 'center',
        });
        credits.anchor.set(0.5,0)
        credits.x = bounds.center.x
        credits.y = 30
        screenLayer.addChild(credits)

        tutorialText = new PIXI.Text('Press space to start', {
            fontFamily: 'Vectrex Big',
            fontSize: 30,
            fill: 0xa79ac8,
            align: 'center',
        });
        tutorialText.anchor.set(0.5,1)
        tutorialText.x = bounds.center.x
        tutorialText.y = bounds.height - 100
        screenLayer.addChild(tutorialText)



        gsap.ticker.add(coreLoop)
    })

    
}
init()

// var music = new Howl({
//     src: ['intro.mp3'],
//     autoplay: true,
//     loop: true,
//     volume: 0.25,
// });






// key handlers
document.addEventListener('keydown', onKeyDown);
function onKeyDown(key) {
    if (key.keyCode === 87 || key.keyCode === 38) {up = true}
    if (key.keyCode === 83 || key.keyCode === 40) {down = true}
    if (key.keyCode === 65 || key.keyCode === 37) {left = true}
    if (key.keyCode === 68 || key.keyCode === 39) {right = true}
    if(key.code === 'Space' ) { space = true ; shooting = true }
}
document.addEventListener('keyup', onKeyUp);
function onKeyUp(key) {
    if (key.keyCode === 87 || key.keyCode === 38) {up = false}
    if (key.keyCode === 83 || key.keyCode === 40) {down = false}
    if (key.keyCode === 65 || key.keyCode === 37) {left = false}
    if (key.keyCode === 68 || key.keyCode === 39) {right = false}
    if(key.code === 'Space' ) { space = false; shooting = false }
}






// Core loop for the games state machine
function coreLoop() {
    switch(state) {
        case 0 : startLoop(); break;
        case 1 : gameLoop(); break;
        case 2 : endLoop(); break;
    }
    filter.seed = Math.random();
    filter.time += 0.5;
}

//
function startLoop() {
    if( space ) {
        state = -1
        titleScreen.destroy()
        tutorialText.destroy()
        credits.destroy()
        startGame()
    }
    guiLayer.alpha = 0
}
 
function gameLoop() {
    guiLayer.alpha = 1
    //player controls
    if(down) {
        player.speed -= settings.playerDeceleration
        player.speed = gsap.utils.clamp(0,settings.playerMaxSpeed,player.speed)
    }
    if(up) {
        player.speed += settings.playerAcceleration
        player.speed = gsap.utils.clamp(0,settings.playerMaxSpeed,player.speed)
    }
    if(left) {
        player.rotation -= player.rotationSpeed
    }
    if(right){
        player.rotation += player.rotationSpeed
    }

    if(space){
        shooting = true
    }
    
    player.x = player.x + player.speed * Math.cos(player.rotation);
    player.y = player.y + player.speed * Math.sin(player.rotation);

    // move up/down catches
    if(player.y >= bounds.height + settings.playerSizeHalf) { player.y = -settings.playerSizeHalf }
    if(player.y < -settings.playerSizeHalf) {player.y = bounds.height + settings.playerSizeHalf }
    // move left/right catches
    if(player.x >= bounds.width + settings.playerSizeHalf) { player.x = -settings.playerSizeHalf }
    if(player.x < -settings.playerSizeHalf) {player.x = bounds.width + settings.playerSizeHalf}

    // SHOOTING
    if(player.currentCoolDown > 0 ) player.currentCoolDown--
    if(shooting && player.currentCoolDown == 0) {
        shoot(player.x,player.y,player.rotation)
    }

    asteroidCountdown--
    if(asteroidCountdown <= 0) {
        let newChildAsteroid = generateAsteroid(sprs,bounds,settings)
        asteroids.push(newChildAsteroid)
        gameLayer.addChild(newChildAsteroid)
        asteroidCountdown = settings.asteroidCountdown
    }


    
    let t = hitTests()
    if(t) state = 1

    if(asteroids.length == 0) {
        state = 2
        gameOver()
    }
}

function hitTests() {
    for(var i = 0; i < asteroids.length; i++ ) {

        asteroids[i].x += asteroids[i].rX
        asteroids[i].y += asteroids[i].rY

        if(asteroids[i].y >= bounds.height + (settings.asteroidSize/2)) { asteroids[i].y = -(settings.asteroidSize/2) }
        if(asteroids[i].y < -(settings.asteroidSize/2)) {asteroids[i].y = bounds.height + (settings.asteroidSize/2) }
        // move left/right catches
        if(asteroids[i].x >= bounds.width + (settings.asteroidSize/2)) { asteroids[i].x = -(settings.asteroidSize/2) }
        if(asteroids[i].x < -(settings.asteroidSize/2)) {asteroids[i].x = bounds.width + (settings.asteroidSize/2)}


        // check for hits against the player
        if(asteroids[i] !== null) {
            if(b.hitTestCircle(asteroids[i],player)) { // b.hit(asteroids[i],player)
                resetGame()
            }
    
            // check for hits against any bullets
            for(var l = 0; l < bullets.length; l++) {
                if(b.hit(asteroids[i],bullets[l])) {
                    state = -1
                    breakAsteroid(asteroids[i],bullets[l])
                    return true 
                }
            } 
            asteroids[i].rotation += asteroids[i].rDir * 0.005
        }
    }
    return false
}


function endLoop() {
    if(space) {
        for (var i = gameLayer.children.length - 1; i >= 0; i--) {	gameLayer.removeChild(gameLayer.children[i]);};
        lives = 3
        livesGUI.text = 'Lives: ' + lives
        score = 0
        scoreGUI.text = 'Score: ' + score
        asteroids.length = 0

        gameOverText.text = ''
        startGame()
        state = 1
    }
}

function breakAsteroid(asteroid,bullet) {
    let newX = asteroid.x
    let newY = asteroid.y
    let asteroidToRemove = asteroid.asteroidID
    let lifeStage = asteroid.lifeStage
    asteroids = asteroids.filter(asteroid => asteroid.asteroidID !== asteroidToRemove);

    gsap.to(asteroid,{
        alpha:0,
        duration:0.05,
        repeat:5,
        yoyo: true,
        onComplete: () => {
            asteroid.destroy()
            
            // let randomScale

            // switch(lifeStage) {
            //     case 2 : randomScale = 0.6; break; 
            //     case 1 : randomScale = 0.3; break;
            // }


            if(lifeStage == 2 || lifeStage == 1) {
                for(var i = 0; i < 3; i++) {
                    let newChildAsteroid = generateAsteroid(sprs,bounds,settings,newX,newY,lifeStage)
                    // newChildAsteroid.scale.x = randomScale
                    // newChildAsteroid.scale.y = randomScale
                    asteroids.push(newChildAsteroid)
                    gameLayer.addChild(newChildAsteroid)
                } 
            }
        }
    })
    bullet.tl.pause()
    bullet.destroy()
    bullets.shift()

    score += settings.pointsPerAsteroid
    scoreGUI.text = 'Score: ' + score
}

function startGame() {
    el.classList.add('active')
    // generate the player
    player = PIXI.Sprite.from(sprs.ship)
    player.anchor.set(0.5,0.4)
    player.x = bounds.center.x
    player.y = bounds.center.y
    player.speed = 0
    player.rotation = 0
    player.currentCoolDown = 0
    player.rotationSpeed = settings.playerRotationSpeed
    player.radius = player.width/2
    gameLayer.addChild(player)
    
    // generate the asteroids
    for(var i = 0; i < settings.asteroidCount; i++) {
        let asteroid = generateAsteroid(sprs,bounds,settings)
        gameLayer.addChild(asteroid)
        asteroids.push(asteroid)
    }

    gameLayer.alpha = 1
    guiLayer.alpha = 1
    // Add the GUI
    
    // Start the game loop
    state = 1
}

function resetGame() {
    state = -1
    if(lives - 1 >= 1 ) {
        player.x = bounds.center.x
        player.y = bounds.center.y
        player.speed = 0
        player.rotation = 0
        gsap.from(player,{
            alpha:0,
            duration:0.1,
            repeat:20,
            yoyo: true,
            onComplete: () => {
                state = 1
            }
        })
        lives--
        livesGUI.text= 'Lives: ' + lives 
        for(var i = 0; i < asteroids.length; i++) {
            asteroids[i].x = (0 - (settings.asteroidSize/2) + 5)
            asteroids[i].y = (0 - (settings.asteroidSize/2) + 5)
        }
    } else {
        // gameover
        state = 2
        gameOver()
    }
    
}

function gameOver() {
    highScore = (score > highScore) ? score : highScore
    gameLayer.alpha = 0
    guiLayer.alpha = 0
    gameOverText = new PIXI.Text('',{
        fontFamily: 'Vectrex Big',
        fontSize: 34,
        fill: 0xa79ac8,
        align: 'center',
    })
    gameOverText.text = 'GAME OVER! \n \n score: ' + score + ' points\n high score: '+highScore+' points \n\n find out more about Galaxy Defenders \n at unitedus.co.uk \n\npress space to play again'
    gameOverText.anchor.set(0.5)
    gameOverText.x = bounds.center.x
    gameOverText.y = bounds.center.y

    screenLayer.addChild(gameOverText)
}

// PLAYER ACTIONS
function shoot(x,y,r) {
    let bullet = PIXI.Sprite.from(sprs.bullet)
    bullet.x = player.x
    bullet.y = player.y
    bullet.alpha = 1

    r = (r < 0) ? Math.abs(r) : -r;


    let targetX = bullet.x + settings.bulletDistance * Math.sin( r + (90 * (Math.PI / 180)) )
    let targetY = bullet.y + settings.bulletDistance * Math.cos( r + (90 * (Math.PI / 180)) )



    gameLayer.addChild(bullet)    
    player.currentCoolDown = settings.bulletCoolDown

    bullet.tl = gsap.timeline()
    bullet.tl.to(bullet,{
        x: targetX,
        y: targetY,
        duration: settings.bulletSpeed,
        ease: 'none',
        onComplete: () => {
            bullet.destroy()
            bullets.shift()
        }
    },0)

    bullet.tl.from(bullet,{
        alpha:0,
        duration:0.05,
        ease: 'none',
    },0)

    bullets.push(bullet)
    
}




